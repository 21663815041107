<template>
  <div class="container py-4">
    <h1 class="text-center mb-4">Условия использования Market Metrics</h1>
    <p>
      ИП Калин Иван Викторович, именуемый в дальнейшем «Правообладатель», с одной стороны, публикует настоящие Условия,
      являющиеся публичной офертой (предложением) по предоставлению доступа к Market Metrics и иных сопутствующих услуг.
      Настоящее соглашение заключается между ИП Калиным Иваном Викторовичем и любым физическим или юридическим лицом.
    </p>
    <p>
      Условия могут быть изменены правообладателем без предварительного уведомления и согласования. Актуальная версия
      оферты в любое время доступна по адресу
      <a href="https://marketmetrics.online/policy" target="_blank">https://marketmetrics.online/policy</a>.
    </p>
    <p>
      Пожалуйста, внимательно ознакомьтесь с данными условиями, т.к. любое использование сайта marketmetrics.online
      означает
      полное принятие условий, описанных в данной публичной оферте.
    </p>

    <h2>1. Термины и определения</h2>
    <p>Нижеприведенные термины используются в следующих значениях:</p>
    <ul>
      <li><strong>Market Metrics</strong> — сайт, размещенный в сети Интернет по адресу https://marketmetrics.online/
        или любой другой адрес в домене marketmetrics.online.
      </li>
      <li><strong>Пользователь (Клиент)</strong> — физическое и/или юридическое лицо, принявшее условия настоящей оферты
        и/или пользующееся Market Metrics.
      </li>
      <li><strong>Услуга</strong> — подключение на определенный срок функциональных возможностей Market Metrics, в
        соответствии с Тарифами и условиями настоящей оферты.
      </li>
      <li><strong>Аккаунт (Учетная запись)</strong> — уникальная учётная запись в Market Metrics, позволяющая
        зарегистрированному Пользователю использовать все её доступные функции.
      </li>
      <li><strong>Тариф</strong> — совокупность функционала, предоставляемого Пользователю в течение оплаченного периода
        использования.
      </li>
      <li><strong>IP-адрес</strong> — уникальный сетевой адрес узла в компьютерной сети, с которого идет подключение
        Пользователя к Market Metrics.
      </li>
    </ul>

    <h2>2. Общие положения</h2>
    <p>2.1. Правообладатель предоставляет Пользователям доступ к данным Market Metrics на условиях платной подписки.</p>
    <p>2.2. Пользователь понимает и соглашается с тем, что Правообладатель не берет на себя ответственность за
      своевременность, удаление, неверную доставку или невозможность хранения сообщений любому пользователю или
      персональных настроек.</p>
    <p>2.3. Пользователь несёт ответственность за получение доступа к Market Metrics, и понимает, что использование
      Market Metrics может быть сопряжено со сборами третьих лиц (например, оплата услуг Интернет-провайдера и т.п.).
      Пользователь сам целиком несёт ответственность за эти расходы. Кроме того, Пользователь несёт ответственность за
      всё оборудование, необходимое Пользователю для доступа к Сервису.</p>

    <h2>3. Оплата услуг и отчетность</h2>
    <p>3.1. Стоимость Услуг определяется в соответствии с Тарифами на использование, расположенными по адресу:
      https://marketmetrics.online/</p>
    <p>3.2. Правообладатель вправе в любое время в одностороннем порядке изменять Тарифы.</p>
    <p>3.3. Доступ к Сервису оплачивается Клиентом на условиях предоплаты.</p>
    <p>3.4. Датой оказания Услуг Исполнителем является дата осуществления подключения (продления) на определенный срок
      функциональных возможностей Market Metrics.</p>
    <p>3.5. В течение пяти рабочих дней с момента оказания Услуг (подключения на определенный срок функциональных
      возможностей Market Metrics, в соответствии с Тарифами и условиями настоящей оферты) правообладатель формирует
      односторонний Акт об оказанных услугах в соответствии со стоимостью оказанных услуг и счёт-фактуру, либо
      универсальный передаточный документ (УПД).</p>
    <p>3.6. Услуги считаются оказанными надлежащим образом и принятыми Пользователем в указанном в Акте объеме, если в
      течение пяти рабочих дней с момента выставления Акта правообладатель не получил от Клиента мотивированных
      письменных возражений.</p>
    <p>3.7. По истечении срока, указанного выше (пяти рабочих дней), претензии Пользователя относительно недостатков
      Услуг, в том числе по количеству (объему), стоимости и качеству не принимаются.</p>
    <p>3.8. Расходы банка Клиента, в том числе банковская комиссия, по перечислению Клиентом денежных средств по данному
      Договору возлагаются на Пользователя.</p>

    <h2>4.Условия использования сервиса</h2>
    <p>4.1. Регистрация в Market Metrics осуществляется Клиентом самостоятельно путем указания своих Учетных данных на
      странице регистрации
      Элементом, идентифицирующим Клиента в Market Metrics, является адрес электронной почты, указанный при регистрации
      (учетные данные).
    </p>
    <p>4.2. Клиент несет полную ответственность за действия и/или бездействие, приведшие к разглашению, потере, краже и
      т.п. его учетных данных и иной информации, индивидуализирующей Клиента, а также за любые действия и/или
      бездействие третьих лиц, использующих учетные данные Клиента. Исполнитель не несет ответственности за
      вышеуказанные действия Клиента и/или третьих лиц, использующих его учетные данные</p>
    <p>4.3. При использовании Market Metrics запрещаются любые действия, направленные на получение несанкционированного
      доступа к ресурсам серверов Market Metrics, учетным и иным данным других клиентов.</p>
    <p>4.4. Использование сервиса должно осуществляться Клиентом только для законных целей и законными способами с
      учетом законодательства РФ, Европейского союза и США.</p>
    <p>4.5. Клиент обязуется не наносить ущерб программной оболочке, техническим и программным средствам, узловым
      машинам Сервиса Market Metrics и третьих лиц.</p>
    <p>4.6. Клиент обязуется соблюдать авторские права на предоставляемые Market Metrics и и/или третьими лицами
      программное обеспечение и документацию.</p>
    <p>4.7. Клиент обязуется не предоставлять свои Учетные данные третьим лицам.</p>
    <p>4.8. Для осуществления доступа к Сервису, пользователь передает Market Metrics (сохраняет в своем Личном
      кабинете) свои API ключи кабинета поставщика «Wildberries» и «Ozon»; правообладатель предоставляет пользователю
      свои заверения (гарантии), что предоставленные API ключи кабинета поставщика «Wildberries» и «Ozon» будут
      использованы исключительно в целях получения (исключая цели размещения/предоставления какой-либо информации) через
      такой «кабинет» необходимых отчетов, статистических/информационных данных для формирования Сервисом согласно
      Тарифного плана данных, в частности, но, не ограничиваясь перечисленным: поставки, склад, заказы, продажи, отчет о
      продажах по реализации, платное хранение - факт, оборачиваемость по брендам и товарам, сведения о товаре в пути к
      клиентам/от клиентов, сведения о «листах ожидания», о ценах, акциях и скидках, и прочие информационные данные для
      поддержания актуальной статистики и построения необходимой аналитики.</p>

    <h2>5.Запрещенные действия</h2>
    <p>5.1. Пользователям Market Metrics запрещается вводить для анализа и поиска любые данные, имеющие порнографическое
      содержание; описание запрещенных в РФ товаров и услуг; информацию, способствующую развитию религиозных
      конфликтов.</p>
    <p>5.2. В случае обнаружения подобных запросов или доменов, связанных с Аккаунтом, Сервис имеет право
      незамедлительно и необратимо заблокировать Аккаунт Пользователя.</p>

    <h2>6. Блокировка Аккаунта</h2>
    <p>6.1. Сервис Market Metrics оставляет за собой право блокировки Аккаунта в случае возникновения следующих
      ситуаций:
    </p>
    <ul>
      <li>Получения больше 5000 запросов к Сервису в течение дня;</li>
      <li>При использовании Сервиса более чем с 3 ip адресов одновременно;</li>
      <li>При использовании Сервиса напрямую через программный интерфейс.</li>
    </ul>

    <h2>7. Конфиденциальность</h2>
    <p>7.1. Любая личная или общедоступная информация, которую Пользователь передает Market Metrics, является
      конфиденциальной. Никакие данные не будут переданы, проданы или использованы совместно с третьими лицами. Market
      Metrics не агрегирует и не собирает никаких данных о Пользователях, кроме данных Учетных записей.</p>
    <p>7.2. Всю платежную информацию Пользователь вводит на страницах платежных систем и агрегаторов, эта информация не
      передается и не обрабатывается Market Metrics не агрегирует и не собирает никаких данных о Пользователях, кроме
      данных Учетных записей., Исполнитель не несет ответственности за сохранность этих данных.</p>

    <h2>8. Срок действия и прекращение</h2>
    <p>8.1. Настоящий договор вступает в силу с момента начала использования или регистрации Пользователя в Market
      Metrics и действует до момента удаления Аккаунта Пользователя из Market Metrics.</p>
    <p>8.2. Для удаления Аккаунта Пользователю необходимо написать на адрес marketmetrics@yandex.ru письмо с требованием
      аннулировать аккаунт.</p>

    <h2>9. Форс-мажор</h2>
    <p>9.1. Ни одна из Сторон не будет нести ответственности за неисполнение или задержку в исполнении каких-либо
      обязательств по настоящему Договору, за исключением обязательств оплаты в случае, если это неисполнение или эта
      задержка в исполнении вызваны обстоятельствами вне сферы контроля соответствующей Стороны, включая стихийные
      бедствия, войны, террористические акты, гражданские беспорядки, трудовые конфликты и т.д.</p>

    <h2>10. Прочие условия</h2>
    <p>10.1. Правообладатель, его сотрудники и партнеры не имеют доступа к кабинету поставщика пользователя.</p>
    <p>10.2. Пользователь использует Market Metrics на собственный страх и риск. Функционал сервиса предоставляется «как
      есть» и «по возможности». Сервис, его сотрудники, партнеры и лицензиаты однозначно отказываются от всех гарантий
      любого вида.</p>
    <p>Правообладатель, его сотрудники и партнеры не гарантируют, что:</p>
    <ul>
      <li>Market Metrics будет отвечать требованиям Пользователя;</li>
      <li>Market Metrics будет работать непрерывно, быстро, надежно и без ошибок;</li>
      <li>Качество любых продуктов, услуг и информации, полученных в процессе взаимодействия с Market Metrics, будут
        отвечать ожиданиям Пользователя;
      </li>
      <li>Любые ошибки программного обеспечения Market Metrics могут и будут исправлены.</li>
    </ul>
    <p>10.4. Сервис не несет ответственности перед Пользователем за любой прямой, косвенный, случайный, специальный,
      опосредованный ущерб, включая, но не ограничиваясь, ущербом прибыли, имиджу, информации, а также другие
      материальные убытки.</p>

    <footer class="mt-5">
      <p><strong>ИП Калин Иван Викторович</strong></p>
      <p>ИНН: 522204387010</p>
      <p>ОГРНИП: 316527500088238</p>
      <p>Контактный email: <a href="mailto:marketmetrics@yandex.ru">marketmetrics@yandex.ru</a></p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Policy"
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}

h1, h2 {
  color: #333;
}

p {
  color: #555;
  line-height: 1.6;
}

ul {
  color: #555;
}

footer {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
}
</style>
